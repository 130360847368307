import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { Container, Grid, CssBaseline, TextField, Button, Typography, Box, InputAdornment, IconButton, CircularProgress, Alert } from '@mui/material'
import { ArrowBack as ArrowBackIcon, Visibility, VisibilityOff } from '@mui/icons-material'
import Copyright from './components/login/Copyright'
import logo from './retair_logo.png'
import Footer from './components/login/Footer'

const activateAccount = async (userData, setIsSuccessChange, setError) => {
  const body = {
    id: userData.id
  }
  const response = await fetch('/activate_user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(body),
  });
  const result = await response.json();
  if (!result.isOk) {
    setError(result.message);
    return;
  }
  else setIsSuccessChange(result.isOk)
}

const sendNewPassword = async (userData, setError, setIsSuccessChange) => {
  const response = await fetch('/change_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(userData),
  });
  const result = await response.json();

  if (!result.is_ok) {
    setError(result.message);
    return;
  }
  activateAccount(userData, setIsSuccessChange, setError)
};
const getPreCheckResult = async (userData, setError) => {
  const response = await fetch('/check_pre_reset', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(userData),
  });
  const result = await response.json();

  setError(result.is_ok);
  return result;
};
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ActivateAccountForm = ({
  setError, setIsSuccessChange, id, uuid,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
        newPassword: '',
        confirmPassword: '',
    },
    onSubmit: (values) => {
      setError(null);
      sendNewPassword({ ...values, id, uuid }, setError, setIsSuccessChange);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <TextField
        id="password"
        label={t('login.makeNewPassword')}
        variant="outlined"
        margin="normal"
        type={showPassword ? 'text' : 'password'}
        required
        fullWidth
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...formik.getFieldProps('newPassword')}
      />
      <TextField
        id="confirm_password"
        label={t('login.confirmPassword')}
        variant="outlined"
        margin="normal"
        type={showPassword ? 'text' : 'password'}
        required
        fullWidth
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...formik.getFieldProps('confirmPassword')}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Button
            disabled={formik.values.newPassword === formik.values.confirmPassword ? false : true}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{margin: '24px 0px 16px'}}
          >
            {t('login.saveNewPassword')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const ActivateAccount = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [error, setError] = useState(null);
  const [isSuccessCheck, setIsSuccessCheck] = useState(null);
  const [isSuccessChange, setIsSuccessChange] = useState(null);
  const query = useQuery();

  const id = query.get('id');
  const uuid = query.get('key');

  useEffect(() => {
    if (isSuccessCheck === null) {
      getPreCheckResult({ id, uuid }, setIsSuccessCheck);
    }
    return () => {
      setIsSuccessCheck(null);
    };
  // eslint-disable-next-line
  }, []);

  const renderError = () => (
    <>
      <Typography component="h1" variant="h5">
        {t('common.error')}
      </Typography>
      <Box py={3}>
        <Alert severity="error">
          <Typography variant="body2">
            {t('login.wrongResetLink')}
          </Typography>
        </Alert>
      </Box>
    </>
  );
  const renderForm = () => (
    <>
      <Typography component="h1" variant="h5">
        {t('login.makeNewPassword')}
      </Typography>
      {error ? <Alert severity="error">{error}</Alert> : null}
      <ActivateAccountForm
        setError={setError}
        setIsSuccessChange={setIsSuccessChange}
        id={id}
        uuid={uuid}
      />
    </>
  );
  const renderLoading = () => {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div style={{marginTop: 64, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
          <div style={{display: 'flex', minHeight: '350px', '& > * + *': { marginLeft: 16 }}}>
            <CircularProgress />
          </div>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  };
  const renderSuccess = () => (
    <div style={{marginTop: 64, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
      <Typography component="h1" variant="h5">
        {t('login.successfulPasswordChange')}
      </Typography>
      <Box py={3}>
        <Alert severity="success">
          <Typography variant="body2">
            {t('login.nowYouCanLogin')}
          </Typography>
        </Alert>
      </Box>
    </div>
  );

  if (isSuccessCheck === null) {
    return renderLoading();
  }
  if (isSuccessChange === true) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div style={{marginTop: 64, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
          <img onClick={() => navigate('/')} src={logo} alt="Логотип Retair" style={{width: 240, height: 120, marginBottom: 24, cursor: 'pointer'}}/>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => navigate('/')}
            startIcon={<ArrowBackIcon />}
          >
            {t('registration.back_to_authorization')}
          </Button>
          {renderSuccess()}
        </div>
        <Footer />
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div style={{marginTop: 64, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
        <img onClick={() => navigate('/')} src={logo} alt="Логотип Retair" style={{width: 240, height: 120, marginBottom: 24, cursor: 'pointer'}}/>
        <Box pb={5} style={{ width: '100%' }}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => navigate('/')}
            startIcon={<ArrowBackIcon />}
          >
            {t('registration.back_to_authorization')}
          </Button>
        </Box>
        {isSuccessCheck ? renderForm() : renderError()}
      </div>
      <Footer />
    </Container>
  );
};

export default ActivateAccount;
