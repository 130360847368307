import React, { useEffect, useState, useContext } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  CssBaseline,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import logo from './retair_logo.png';
import { localStorageKey, handleUserResponse } from './utils/auth-client';
import { AuthContext } from './context/auth-context';

import { UserContext } from './context/user-context';
import setLanguage from './utils/language';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: '36px',
  },
  logo: {
    width: theme.spacing(30),
    height: theme.spacing(15),
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
  },
  language: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  progressRoot: {
    padding: '3px',
    width: '36px',
    height: '36px',
  },
}));

const RegisterForm = ({ setError }) => {
  const authContext = useContext(AuthContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loadRegistration, setLoadRegistration] = React.useState(false);

  const sendRegistrationData = async (userData) => {
    const response = await fetch('/register_user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(userData),
    });
    const result = await response.json();
    setLoadRegistration(false);
    if (!result.is_ok) {
      setError(result.message);
      window.scrollTo(0, 0);
      return;
    }
    if (result.token) {
      authContext.setToken(result.token);
      authContext.setIsAuth(true);
      handleUserResponse(localStorageKey, result.token);
    }

    navigate('/', { success: true });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      family_name: '',
      name: '',
      middle_name: '',
      phone: '',
      organization: '',
      position: '',
      market_sector_id: 1,
      created_by: 1,
    },
    onSubmit: (values) => {
      setLoadRegistration(true);
      setError(null);
      sendRegistrationData(values);
    },

  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <TextField
        disabled={loadRegistration}
        name="email"
        type="email"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label={t('common.email')}
        autoComplete="email"
        autoFocus
        onChange={formik.handleChange}
        value={formik.values.email}
      />

      <TextField
        disabled={loadRegistration}
        name="password"
        type="password"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="password"
        autoComplete="current-password"
        label={t('common.password')}
        onChange={formik.handleChange}
        value={formik.values.password}
      />

      <TextField
        disabled={loadRegistration}
        name="family_name"
        type="text"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="family_name"
        label={t('registration.family_name')}
        autoComplete="family-name"
        onChange={formik.handleChange}
        value={formik.values.family_name}
      />

      <TextField
        disabled={loadRegistration}
        name="name"
        type="text"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="password"
        label={t('registration.name')}
        autoComplete="given-name"
        onChange={formik.handleChange}
        value={formik.values.name}
      />
      <TextField
        disabled={loadRegistration}
        name="middle_name"
        type="text"
        variant="outlined"
        margin="normal"
        fullWidth
        id="middle_name"
        label={t('registration.middle_name')}
        autoComplete="additional-name"
        onChange={formik.handleChange}
        value={formik.values.middle_name}
      />

      <TextField
        disabled={loadRegistration}
        name="position"
        type="text"
        variant="outlined"
        margin="normal"
        fullWidth
        id="position"
        label={t('registration.position')}
        onChange={formik.handleChange}
        value={formik.values.position}
      />

      <TextField
        disabled={loadRegistration}
        name="phone"
        type="tel"
        id="phone"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        label={t('registration.phone')}
        placeholder="+79998887766"
        onChange={formik.handleChange}
        value={formik.values.phone}
      />

      <TextField
        disabled={loadRegistration}
        name="organization"
        type="text"
        id="organization"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={t('registration.organization')}
        onChange={formik.handleChange}
        value={formik.values.organization}
      />

      <input
        hidden
        name="market_sector_id"
        type="number"
        id="market_sector_id"
        required
        onChange={formik.handleChange}
        value={formik.values.market_sector_id}
      />
      <input
        hidden
        name="created_by"
        type="number"
        id="created_by"
        required
        onChange={formik.handleChange}
        value={formik.values.created_by}
      />

      <Button
        disabled={loadRegistration}
        type={loadRegistration ? 'button' : 'submit'}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        {
          loadRegistration
            ? (
              <div className={classes.progressRoot}>
                <CircularProgress style={{ width: '100%', height: '100%', color: 'white' }} />
              </div>
            )
            : t('registration.sign_up_btn')
        }

      </Button>
    </form>
  );
};

const Register = () => {
  const navigate = useNavigate()
  const classes = useStyles();
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const userContext = React.useContext(UserContext);
  /// Инициализация i18next т.к предыдущая инициализация сбилась при роутинге.
  useEffect(() => {
    if (typeof userContext.selLanguage.value !== 'undefined') {
      setLanguage(userContext.selLanguage.value);
      return;
    }
    setLanguage('ru');
  }, [userContext.selLanguage]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img onClick={() => navigate('/')} src={logo} className={classes.logo} alt="Логотип Retair" />
        <Box pb={5} style={{ width: '100%' }}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => navigate('/')}
            startIcon={<ArrowBackIcon />}
          >
            {t('registration.back_to_authorization')}
          </Button>
        </Box>
        {error ? <Alert severity="error">{error}</Alert> : null}

        <Typography component="h1" variant="h5">
          {t('registration.sign_up')}
        </Typography>
        <RegisterForm setError={setError} />
      </div>
    </Container>
  );
};

export default Register;
