import React from 'react'
import { useNavigate } from 'react-router-dom'

const StableNavigateContext = React.createContext()

const StableNavigateContextProvider = ({ children }) => {
    const navigate = useNavigate()
    const navigateRef = React.useRef(navigate)

    return (
        <StableNavigateContext.Provider value={navigateRef}>
            {children}
        </StableNavigateContext.Provider>
    )
}

const useStableNavigate = () => {
    const navigateRef = React.useContext(StableNavigateContext)
    if (navigateRef.current === null)
        throw new Error('StableNavigate context is not initialized')

    return navigateRef.current
}

export {
    StableNavigateContext,
    StableNavigateContextProvider,
    useStableNavigate,
}