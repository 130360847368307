import React from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { useMediaQuery } from '@material-ui/core';

import { themeX, themeDarkMui } from '../styles/theme'
import { themeLight, themeLightMui } from '../styles/themeLight'

import { UserContext } from './user-context'

const SettingsContext = React.createContext();

function SettingsProvider(props) {
  const userContext = React.useContext(UserContext)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
  const themePrefers = prefersDarkMode ? 'dark' : 'light';
  const [currentTheme, setCurrentTheme] = useLocalStorageState('currentTheme', themePrefers);
  const theme = currentTheme === 'light' ? themeLight : themeX
  const themeMui = currentTheme === 'light' ? themeLightMui : themeDarkMui
  const toggleTheme = () => {
    setCurrentTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  React.useEffect(()=>{
    userContext.setCurrentTheme(currentTheme)
  },[currentTheme])

  return (
    <SettingsContext.Provider
      value={{
        currentTheme,
        toggleTheme,
        setCurrentTheme,
        prefersDarkMode,
        themeMui,
        theme
      }}
      {...props}
    />
  );
}

export { SettingsProvider, SettingsContext };
