import { createTheme as createThemeMui } from '@mui/material/styles'
import { createTheme } from '@material-ui/core/styles'

const theme = createTheme()

const themeLightMui = createThemeMui({
	palette: {
	  mode: 'light',
	},
})

const themeLight = createTheme({
  palette: {
    type: 'light',
  },
  colors: {
    common: {
      primary: '#5B4DFF',
      secondary: '#F1F1F1',
      text: {
        primary: '#FFFFFF',
        secondary: '#5B4DFF',
        default: '#3A383D'
      },
      hover: {
        primary: '#5B69FF',
      },
      actions: {
        confirm: "green",
        refuse: 'red'
      }
    },
    chart:{
			primary: ['#00346B', '#4798EE', '#0059B8', '#0046EB', '#5C01EB', '#17E9EB', '#8747EE'],
      grid:'#F1F1F1'
		}
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.6em',
          height: '0.6em',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.grey[500],
          outline: '1px solid slategrey',
        },
      },
    },
  },
});

export { themeLight, themeLightMui }
