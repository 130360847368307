import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Copyright from './Copyright';
import Language from './language';

const useStyles = makeStyles(() => ({
  language: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box mt={8}>
      <Copyright />
      <div className={classes.language}>
        <Language />
      </div>
    </Box>
  );
};

export default Footer;
