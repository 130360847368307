import { createTheme as createThemeMui } from '@mui/material/styles'
import { createTheme } from '@material-ui/core/styles'

const theme = createTheme()

const themeDarkMui = createThemeMui({
	palette: {
	  mode: 'dark',
	},
})

const themeX = createTheme({
	palette: {
		type: "dark"
	},
	sizes: {
		drawerWidth: 340
	},
	colors: {
		common: {
			primary: '#525252',
			secondary: '#333333',
			text: {
				primary: '#FFFFFF',
				secondary: '#FFFFFF',
				default: '#FFFFFF'
			},
			hover: {
				primary: '#575757',
			},
			actions: {
				confirm: "green",
				refuse: 'red'
			}
		},
		chart:{
			primary: ['#22A2DB', '#DB1B28', '#D92B93', '#E89F00', '#5C01EB', '#17E9EB', '#8747EE'],
			grid:'#3A383D'
		}
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'*::-webkit-scrollbar': {
					width: '0.6em',
					height: '0.6em'
				},
				'*::-webkit-scrollbar-track': {
					'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
				},
				'*::-webkit-scrollbar-thumb': {
					backgroundColor: theme.palette.grey[600],
					outline: '1px solid slategrey'
				}
			}
		},
	},
});

export { themeX, themeDarkMui }