import {sendCustomErrorToTelegram} from './send-error-custom'
let jwt = require('jsonwebtoken')

async function simpleCallRpc(context, endpoint, body, appData, userContext) {
  let link = null;
  if (appData) link = '/api/data/rpc/';
  else link = '/api/rpc/';

  let timezone = 'Europe/Moscow'
  try { timezone = Intl.DateTimeFormat().resolvedOptions().timeZone } catch {}

  try {
    const response = await
    fetch(link + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        authorization: `Bearer ${context.token}`,
        timezone: timezone,
        workspacehash: userContext?.selected_workspace?.workspace_hash,
        application: userContext?.selectedApplication?.application_sys_name,
        device: userContext?.deviceIdentity,
        contentwidth: window?.screen?.width,
        contentheight: window?.screen?.height,
        theme: userContext?.currentTheme
      },
      body: JSON.stringify({...body, timezone: timezone}),
      json: true,
    });
    const status = await response.status;
    if (status === 401) {
      const decoded = jwt.decode(context.token, {complete: true})
      const iat = new Date(decoded.payload.iat*1000)
      const exp = new Date(decoded.payload.exp*1000)
      if (exp < iat)
      sendCustomErrorToTelegram(`Сброшен токен пользователя. \nДата создания токена: ${iat} \nДата истечения срока токена: ${exp}
       \nРабочая область: ${body.workspace_hash}\nСостояние авторизации: ${context.isAuth}`, {componentStack: endpoint})
      window.localStorage.removeItem('__retair_token__')
      window.location.reload()
      context.setIsAuth(false);
      context.setToken('');
      if (appData) {
        return {
          workspace_hash: body.workspace_hash,
          data: [],
        };
      }
      return [];
    }
    if (status === 204) {
      if (appData) {
        return {
          workspace_hash: body.workspace_hash,
          data: [],
        };
      }
      return [];
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Ошибка:', error);
    return [];
  }
}

export { simpleCallRpc };
