import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { YMInitializer } from 'react-yandex-metrika';
import App from './App';
import Registration from './Registration';
import PasswordReset from './PasswordReset';
import ForgotPassword from './ForgotPassword';
import ActivateAccount from './ActivateAccount';
import AppProviders from './context';
import { StableNavigateContextProvider } from './context/stable-navigate-context';

// FIXME ВРЕМЕННЫЙ ФИКС КРЕША, ЕСЛИ СТРАНИЦА ПЕРЕВЕДЕНА GOOGLE TRANSLATE
// Подробнее https://github.com/facebook/react/issues/11538#issuecomment-417504600
import patchDOMForGoogleTranslate from './utils/patchDOMForGoogleTranslate';
patchDOMForGoogleTranslate();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppProviders>
    <YMInitializer accounts={[80353315]} options={{ webvisor: true }} version="2" />
    <BrowserRouter>
      <StableNavigateContextProvider>
        <CssBaseline />
        <Routes>
          <Route path="" element={<App/>} />
          <Route path="registration" element={<Registration/>} />
          <Route path="forgot">
            <Route path="password" element={<ForgotPassword/>} />
            <Route path="password-reset" element={<PasswordReset/>} />
          </Route>
          <Route path="activate-account" element={<ActivateAccount/>} />
          <Route path="profile/*" element={<App/>} />
          <Route path="apps/*" element={<App/>} />
          <Route path="colleagues" element={<App/>} />
          <Route path="settings/*" element={<App/>} />
        </Routes>
      </StableNavigateContextProvider>
    </BrowserRouter>
  </AppProviders>
);
