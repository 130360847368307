import React from 'react'
import { AuthContext } from './auth-context'
import { UserContext } from './user-context'

import { simpleCallRpc } from '../utils/api-client'
import { simpleCallRpcMainDB } from '../utils/api-client-main-db'
import {useInterval} from 'react-use';

const MiscContext = React.createContext();

function MiscProvider(props) {
  const authContext = React.useContext(AuthContext);
  const userContext = React.useContext(UserContext);

  const [infoNewVersion, setInfoNewVersion] = React.useState(0)
  const [openInfoVersion, setOpenInfoVersion] = React.useState(false)

  const [onlineUsers, setOnlineUsers] = React.useState([])

  React.useEffect(() => {
    if (+userContext.infoNewVersion) {
      setOpenInfoVersion(true)
    } 
  }, [userContext.infoNewVersion])
  
  const getOnlineUsers = (workspace_hash) => {
    // Если приложение в фокусе, получаем онлайн пользователей + помечаем пользователя как онлайн
    if (!document.hidden)
      simpleCallRpcMainDB(authContext, 'get_workspace_online_users', {
        workspace_hash: workspace_hash
      }, true)
        .then(result => {
          if (Array.isArray(result.data)) {
            setOnlineUsers(result.data)
            setInfoNewVersion(result.data[0]?.build_version)
            console.log('--Current version______: ', result.data[0]?.build_version)
            const version = localStorage.getItem('build_version') ?? ''
            if (+result.data[0]?.build_version === +version) {
              setInfoNewVersion(0)
            } else {
              localStorage.setItem('build_version', result.data[0]?.build_version);
              setInfoNewVersion(result.data[0]?.build_version)
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
  }
  
  useInterval(
    () => {
      if (authContext.isAuth && ('workspace_hash' in userContext.selected_workspace) && typeof (userContext.selected_workspace?.workspace_hash) !== "undefined") {
        if (userContext.selected_workspace?.workspace_hash) {
          getOnlineUsers(userContext.selected_workspace?.workspace_hash)
        }
      }
    },
    10000
  );
  

  return (
    <MiscContext.Provider
      value={{
        onlineUsers,
        infoNewVersion,
        openInfoVersion, setOpenInfoVersion
      }}
      {...props}
    />
  );
}

export { MiscProvider, MiscContext };
