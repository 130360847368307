import React from 'react'
import { AuthContext } from './auth-context'
import { simpleCallRpc } from '../utils/api-client'
import { simpleCallRpcMainDB } from '../utils/api-client-main-db'
import setLanguage from '../utils/language/index'
import readCookie from '../utils/readCookie'
import useLocalStorageState from 'use-local-storage-state'
import { v4 as uuidv4 } from 'uuid'

const UserContext = React.createContext();

function UserProvider(props) {
  const authContext = React.useContext(AuthContext);

  const [workspaces, setWorkspaces] = React.useState([]);
  const [selected_workspace, setSelectedWorkspace] = React.useState({});
  const [applications, setApplications] = React.useState(null);
  const [colleagues, setColleagues] = React.useState([]);
  const [selectedApplicationOrColleague, setSelectedApplicationOrColleague] = React.useState('nothing');
  const [selectedApplication, setSelectedApplication] = React.useState(null);
  const [selectedColleague, setSelectedColleague] = React.useState(0);
  const [qwepToken, setQwepToken] = React.useState('');
  const [userInfo, setUserInfo] = React.useState({
    user_id: -1,
    name: ' ',
    family_name: 'Загружаем...',
    user_role_id: null,
    user_role_name: 'Проверяем доступ...',
    description: { premitted_actions: [' '], prohibited_actions: [' '] },
  });
  const [deviceIdentity, setDeviceIdentity] = useLocalStorageState('__retair_d_id__', '')
  /// Текущий выбранный язык
  const [selLanguage, setSelLanguage] = React.useState({});
  /// Все доступные языки
  const [allLanguages, setAllLanguages] = React.useState([{}]);
  const [workspaceBootstraped, setWorkspaceBootstraped] = React.useState(false);
  // Подключение слушателя состояния открытой вкладки (если пользователь в другой вкладке браузера или любое другое приложение в фокусе - то document.hidden = true)
  document.addEventListener("visibilitychange", function () { });
  const [currentTheme, setCurrentTheme] = React.useState('')
  const [selectedOrganization, setSelectedOrganization] = useLocalStorageState('organization_id','')


  const getBrowserLanguage = () => {
    /// СНГ языки (для включения им по умолчанию русского)
    const cisLanguages = ['ru', 'be', 'uk', 'kk', 'az', 'ky', 'tg', 'hy', 'tt'];
    let lang = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage
    lang = lang.split('-')[0];

    /// Если текущий язык входит в состав СНГ языков
    if (cisLanguages.indexOf(lang) !== -1) {
      lang = 'ru';
    } else {
      lang = 'en';
    }

    return (String(lang));
  };

  const getUserLanguageFromDB = async () => {
    let language = {};
    const result = await simpleCallRpc(authContext, 'get_user_language', {});
    language = { label: result[0]?.label, value: result[0]?.value, updated: new Date(Date.parse(result[0]?.updated)) };
    return language;
  };

  const getAllLanguages = () => {
    const languages = [];
    fetch('/language', {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        // eslint-disable-next-line
        data.result.map((elem) => {
          languages.push({ label: elem.label, value: elem.value });
        });
        setAllLanguages(languages);
      });
    return (languages);
  };

  const setLanguageInState = (languageFromDB) => {
    /// Язык браузера.
    const browserLanguage = getBrowserLanguage();
    /// Язык из куков.
    const languageFromCookie = JSON.parse(readCookie('language_info'));

    const indexLanguageBrowser = allLanguages.findIndex((elem) => browserLanguage === elem.value);
    const indexLanguageCookie = allLanguages.findIndex((elem) => languageFromCookie?.language === elem.value);
    const indexLanguageDB = allLanguages.findIndex((elem) => languageFromDB.value === elem.value);

    /// Если в куках или бд хранится информация о выбранном языке.
    if (indexLanguageCookie !== -1 || indexLanguageDB !== -1) {
      if (typeof languageFromDB.updated !== 'undefined' && languageFromCookie != null) {
        /// Если язык в куках был обновлен позже языка из бд.
        if ((new Date(languageFromCookie.set_date)).toUTCString() > (languageFromDB.updated).toUTCString()) {
          /// Задать язык в системе язык из куков.
          setSelLanguage(allLanguages[indexLanguageCookie]);
          /// Если пльзователь авторизован.
          if (authContext.isAuth) {
            /// Записать язык в базу данных.
            simpleCallRpc(authContext, 'set_language', { value: allLanguages[indexLanguageCookie].value });
            document.cookie = 'language_info =; expires = Thu, 01 Jan 1970 00:00:00 GMT';
          }
        } else {
          /// Выставить язык из бд.
          setSelLanguage(allLanguages[indexLanguageDB]);
          document.cookie = 'language_info =; expires = Thu, 01 Jan 1970 00:00:00 GMT';
        }
      } else if (indexLanguageCookie !== -1) {
        /// Задать язык в системе язык из куков.
        setSelLanguage(allLanguages[indexLanguageCookie]);
        if (authContext.isAuth) {
          /// Записать язык в базу данных.
          simpleCallRpc(authContext, 'set_language', { value: allLanguages[indexLanguageCookie].value });
          document.cookie = 'language_info =; expires = Thu, 01 Jan 1970 00:00:00 GMT';
        }
      } else {
        setSelLanguage(allLanguages[indexLanguageDB]);
      }
    } else {
      setSelLanguage(allLanguages[indexLanguageBrowser]);
    }
  };

  React.useEffect(() => {
    /// Установка языка в i18next
    setLanguage(typeof selLanguage !== 'undefined' && selLanguage.value ? selLanguage.value : 'ru');
  }, [selLanguage]);

  React.useEffect(() => {
    /// если массив пустой
    if (allLanguages.length === 1 && typeof allLanguages[0].label === 'undefined') {
      /// Получить все языки
      getAllLanguages();
    } else if (authContext.isAuth) {
      /// Получение языка из базы данных.
      getUserLanguageFromDB()
        .then((languageFromDB) => {
          setLanguageInState(languageFromDB);
        });
    } else {
      setLanguageInState({});
    }
    // eslint-disable-next-line
  }, [allLanguages, authContext.isAuth]);

  React.useEffect(() => {
    if (authContext.isAuth && ('workspace_hash' in selected_workspace) && typeof (selected_workspace?.workspace_hash) !== "undefined") {
      (async () => {
        const qwepTokenResult = await simpleCallRpc(
          authContext,
          'get_qwep_token',
          { workspace_hash: selected_workspace?.workspace_hash },
          true,
        );
        if (qwepTokenResult.data?.length > 0) {
          setQwepToken(qwepTokenResult.data[0].token);
        }

        const userInfoResult = await simpleCallRpc(
          authContext,
          'get_user_info',
          { workspace_hash: selected_workspace?.workspace_hash },
        );
        setUserInfo(userInfoResult[0]);
      })();
    }
    // eslint-disable-next-line
  }, [authContext.isAuth, selected_workspace]);

  React.useEffect(() => {
    if ('workspace_hash' in selected_workspace) {
      simpleCallRpc(authContext, 'get_user_applications', { workspace_hash: selected_workspace?.workspace_hash })
        .then((result) => { setApplications(result); });
    }
    // eslint-disable-next-line
  }, [selected_workspace])

  React.useEffect(()=>{
    if(deviceIdentity === '') setDeviceIdentity(uuidv4())
  },[deviceIdentity])

  return (
    <UserContext.Provider
      value={{
        workspaces,
        setWorkspaces,
        selected_workspace,
        setSelectedWorkspace,
        applications,
        setApplications,
        colleagues,
        setColleagues,
        selectedApplicationOrColleague,
        setSelectedApplicationOrColleague,
        selectedApplication,
        setSelectedApplication,
        selectedColleague,
        setSelectedColleague,
        qwepToken,
        setQwepToken,
        userInfo,
        selLanguage,
        setSelLanguage,
        allLanguages,
        workspaceBootstraped,
        setWorkspaceBootstraped,
        deviceIdentity,
        currentTheme, setCurrentTheme,
        selectedOrganization, setSelectedOrganization
      }}
      {...props}
    />
  );
}

export { UserProvider, UserContext };
