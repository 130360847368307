import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { AuthProvider } from './auth-context';
import { UserProvider } from './user-context';
import { SettingsProvider } from './settings-context';
import { SMProvider } from './sm-context';
import { ContentTypeProvider } from './content-context';
import { MiscProvider } from './misc-context';

import i18n from '../utils/language/i18n';

function AppProviders({ children }) {
  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <UserProvider>
          <MiscProvider>
            <SMProvider>
              <SettingsProvider>
                <ContentTypeProvider>
                  {children}
                </ContentTypeProvider>
              </SettingsProvider>
            </SMProvider>
          </MiscProvider>
        </UserProvider>
      </AuthProvider>
    </I18nextProvider>
  );

}
export default AppProviders;
