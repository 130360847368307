import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles"

import { UserContext } from '../../context/user-context'

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    button: {
        height: '35px',
        borderRadius: "25px",
        paddingLeft: "15px",
        paddingRight: '15px',
    },
    iconButton: {
        '&.MuiSvgIcon-root': {
            fontSize: "24px",
        },
    },
    paper: {
        marginRight: theme.spacing(2)
    }
}));

export default function LanguageMenu() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const userContext = React.useContext(UserContext)
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {

        if (event.key === "Tab") {
            event.preventDefault();

            setOpen(false);
        }
    }
    function changeLanguage(index){
        let dateNow = new Date();
        let dateExpires = new Date();
        let expires = '';
        // fetch('http://worldtimeapi.org/api/timezone/Europe/London',{
        //     method:"GET"
        // })
        // .then(response => response.json())
        // .then(result => console.log(result.))

        dateExpires.setTime(dateExpires.getTime() + (10 * 24 * 60 * 60 * 1000));
        expires = " expires=" + dateExpires;
  
        document.cookie = `language_info={"language":"${userContext.allLanguages[index].value}","set_date":"${dateNow}"};${expires}`
        userContext.setSelLanguage(userContext.allLanguages[index]);
        setOpen(false);
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    className={classes.button}
                    onClick={handleToggle}
                >
                    {userContext.selLanguage?.label}
                </Button>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom"
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        {userContext.allLanguages.map((elem,index) => {
                                            return (
                                                <MenuItem key={elem.label} onClick={() => changeLanguage(index)}>{elem.label}</MenuItem>
                                            )
                                        })}

                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}
