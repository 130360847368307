import React from 'react';
import { UserContext } from '../../context/user-context'

const getActivityFromLocalStorage = () => {
    const data = localStorage.getItem('activity_user');

    if (data != null) {
        const arrData = JSON.parse(data);
        return arrData;
    }
    return [];
}

const getPath = (event) => {
    /// Если нет метода получения пути в событиях(если это мозила или сафари)
    if (!("path" in Event.prototype)){
        let path = [];
        let element = event.target;
        while (element) {
            path.push(element);
            element = element.parentElement;
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
        path.push(document);
        if (path.indexOf(window) === -1)
        path.push(window);
        return path.slice();
    } else {
        return event.path.slice();
    }
} 

const recEventInLocalStorage = (e, context) => {

    let elements = getPath(e);
    // console.log(e.path)
    // console.log(elements)
    /// Отслеживаемый элемент.
    let trackingElement = null;
    const selApplication = context.selectedApplication?.application_sys_name;
    const selWorkspace = context.selected_workspace?.workspace_hash;
    const userInfo = context.userInfo;
    const typeActivity = e.type;
    let activity_name = null;
    const data = getActivityFromLocalStorage(activity_name)
    let htmlString = '';


    // ///Удаление последних 5ти элементов пути(тех родителей, которые у всех элементов одинаковые)
    // elements.splice(elements.length - 5);


    /// Проход по дереву от вызванного компонента до самого родительского компонента с целью поиска аттрибута 'activity_name'
    for (let i = 0; i < elements.length; i++) {
        /// Проверка существования функции
        if (typeof elements[i].getAttribute !== 'undefined') {
            if (elements[i].getAttribute('activity_name') != null) {
                trackingElement = elements[i];
                activity_name = trackingElement.getAttribute('activity_name');
                htmlString = trackingElement.outerHTML
                break;
            }
        }
    }

    /// Если у элемента есть атрибут activity_name
    if (activity_name != null) {
        const timestamp = Date.parse(new Date());
        
        const activity = {
            user_id: userInfo.user_id,
            activity_name: activity_name,
            timestamp: timestamp,
            type_activity: typeActivity,
            target_value: JSON.stringify(htmlString),
            selected_application: selApplication,
            selected_workspace: selWorkspace
        }
        data.push(activity);
        /// Записать в local storage
        localStorage.setItem(`activity_user`, JSON.stringify(data));
    }


    if (data.length > 0) {
        let currentDate = new Date();
        let durationInMinutes = 1;
        let dateLastActivity = new Date(data[0].timestamp);
        currentDate.setMinutes(currentDate.getMinutes() - durationInMinutes);

        if (currentDate > dateLastActivity) {
            /// Отправить на сервер активность пользователя
            recInDB();
        }


    }
}

const recInDB = () => {
    let data = getActivityFromLocalStorage()
    localStorage.removeItem('activity_user');
    fetch('/user_activity', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({
            activity: data
        }),
    })

}
export default function UserActivity() {
    const userContext = React.useContext(UserContext)
    document.body.onclick = function (e) {
        recEventInLocalStorage(e, userContext);
    }
    document.body.onchange = function (e) {
        recEventInLocalStorage(e, userContext);
    }
    document.body.onkeydown = function (e) {
        /// Нажатие на Enter   
        if (e.keyCode === 13) {
            recEventInLocalStorage(e, userContext);
        }
    }
    return (
        <></>
    )
}