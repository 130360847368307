import React from 'react';
import { UserContext } from './user-context'
import { AuthContext } from './auth-context'
import { simpleCallRpc } from '../utils/api-client'


const ContentTypeContext = React.createContext();

function ContentTypeProvider(props) {
    const userContext = React.useContext(UserContext)
    const authContext = React.useContext(AuthContext)
    const [dataContentType, setDataContentType] = React.useState([])

    React.useEffect(() => {
        if (userContext.selected_workspace.workspace_hash && userContext.selectedApplication?.application_sys_name === 'e-commerce') {
            getContentType()
        }
    //eslint-disable-next-line
    }, [userContext.selected_workspace.workspace_hash, userContext.selectedApplication])

    
    const getContentType = async () => {
        const result = await simpleCallRpc(authContext, 'app_ecom_get_content_type_by_hash', {
            workspace_hash: userContext.selected_workspace?.workspace_hash,
            },
            false,userContext
            )
            if (result.length) {
                setDataContentType(result)
        } else {
            setDataContentType([])
        }

    }




    return (
        <ContentTypeContext.Provider
            value={{
                dataContentType,
            }}
        {...props}
        />
    );
}

export { ContentTypeProvider, ContentTypeContext }