import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  CssBaseline,
  TextField,
  Button,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ArrowBack as ArrowBackIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import Copyright from './components/login/Copyright';
import logo from './retair_logo.png';
import Footer from './components/login/Footer';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: theme.spacing(30),
    height: theme.spacing(15),
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
  },
  loader: {
    display: 'flex',
    minHeight: '350px',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const sendNewPassword = async (userData, setError, setIsSuccessChange) => {
  const response = await fetch('/change_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(userData),
  });
  const result = await response.json();

  if (!result.is_ok) {
    setError(result.message);
    return;
  }
  setIsSuccessChange(result.is_ok);
};
const getPreCheckResult = async (userData, setError) => {
  const response = await fetch('/check_pre_reset', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(userData),
  });
  const result = await response.json();

  setError(result.is_ok);
  return result;
};
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ForgotPasswordForm = ({
  setError, setIsSuccessChange, id, uuid,
}) => {
  const { t } = useTranslation();
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
    },
    onSubmit: (values) => {
      setError(null);
      console.log(values);
      sendNewPassword({ ...values, id, uuid }, setError, setIsSuccessChange)
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <TextField
        id="password"
        label={t('login.makeNewPassword')}
        variant="outlined"
        margin="normal"
        type={showPassword ? 'text' : 'password'}
        required
        fullWidth
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...formik.getFieldProps('newPassword')}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t('login.saveNewPassword')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const PasswordReset = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate()
  const [error, setError] = useState(null);
  const [isSuccessCheck, setIsSuccessCheck] = useState(null);
  const [isSuccessChange, setIsSuccessChange] = useState(null);
  const query = useQuery();

  const id = query.get('id');
  const uuid = query.get('key');

  useEffect(() => {
    if (isSuccessCheck === null) {
      getPreCheckResult({ id, uuid }, setIsSuccessCheck);
    }
    return () => {
      setIsSuccessCheck(null);
    };
  // eslint-disable-next-line
  }, []);

  const renderError = () => (
    <>
      <Typography component="h1" variant="h5">
        {t('common.error')}
      </Typography>
      <Box py={3}>
        <Alert severity="error">
          <Typography variant="body2">
            {t('login.wrongResetLink')}
          </Typography>
        </Alert>
      </Box>
    </>
  );
  const renderForm = () => (
    <>
      <Typography component="h1" variant="h5">
        {t('login.makeNewPassword')}
      </Typography>
      {error ? <Alert severity="error">{error}</Alert> : null}
      <ForgotPasswordForm
        setError={setError}
        setIsSuccessChange={setIsSuccessChange}
        id={id}
        uuid={uuid}
      />
    </>
  );
  const renderLoading = () => {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  };
  const renderSuccess = () => (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        {t('login.successfulPasswordChange')}
      </Typography>
      <Box py={3}>
        <Alert severity="success">
          <Typography variant="body2">
            {t('login.nowYouCanLogin')}
          </Typography>
        </Alert>
      </Box>
    </div>
  );

  if (isSuccessCheck === null) {
    return renderLoading();
  }
  if (isSuccessChange === true) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img onClick={() => navigate('/')} src={logo} className={classes.logo} alt="Логотип Retair" />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => navigate('/')}
            startIcon={<ArrowBackIcon />}
          >
            {t('registration.back_to_authorization')}
          </Button>
          {renderSuccess()}
        </div>
        <Footer />
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img onClick={() => navigate('/')} src={logo} className={classes.logo} alt="Логотип Retair" />
        <Box pb={5} style={{ width: '100%' }}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => navigate('/')}
            startIcon={<ArrowBackIcon />}
          >
            {t('registration.back_to_authorization')}
          </Button>
        </Box>
        {isSuccessCheck ? renderForm() : renderError()}
      </div>
      <Footer />
    </Container>
  );
};

export default PasswordReset;
