import React, { useEffect, useContext } from 'react';
import { AuthContext } from './auth-context';
import { UserContext } from './user-context';
import { simpleCallRpc } from '../utils/api-client';
import { useLocalStorage } from 'react-use';

const SMContext = React.createContext();

function SMProvider(props) {
  const authContext = useContext(AuthContext);
  const userContext = React.useContext(UserContext)

  const [logisticSchema, setLogisticSchema] = React.useState([])
  const [chosenLogisticSchema, setChosenLogisticSchema] = useLocalStorage('chosenLogisticSchemaList', {workspace: '',lastUpdate:'', list: []}) //React.useState([])
  const [allCentralDivisions, setAllCentralDivisions] = React.useState([])
  const [chosenCentralDivisions, setChosenCentralDivisions, removeChosenCentralDivisions] = useLocalStorage('chosenLocations', {workspace: '',lastUpdate:'', locations: [],exclude:[]})
  const [allDaughterDivisions, setAllDaughterDivisions] = React.useState([])
  const [centralDivisionsOfChosenLogisticSchema, setCentralDivisionsOfChosenLogisticSchema] = React.useState([])
  const [chosenDivisionListChanged,setChosenDivisionsListChanges] = React.useState(false)
  const [chosenSkuFolders, setChosenSkuFolders, removeChosenSkuFolders] = useLocalStorage('chosenSkuFolders', {workspace: '', folders: []})
  const [loadingAllLogisticSchema, setLoadingAllLogisticSchema] = React.useState(false)
  const [loadingAllCentralDivisions, setLoadingAllCentralDivisions] = React.useState(false)
  const [loadingAllOrganizations, setLoadingAllOrganizations] = React.useState(false)
  const [loadingAllSkuFolders, setLoadingAllSkuFolders] = React.useState(false)
  const [organizations, setOrganizations] = React.useState([])
  const [skuFolders, setSkuFolders] = React.useState([])
  const [chosenDivisionChanged, setChosenDivisionChanged] = React.useState(false)
  const [chosenSkuFoldersChanged, setChosenSkuFoldersChanged] = React.useState(false)
  const [updateBasketCounter, setUpdateBasketCounter] = React.useState(0)
  const [clearBasketCounter, setClearBasketCounter] = React.useState(0)
  const [basketInfo, setBasketInfo] = React.useState({})
  const [basketInfoInterval, setBasketInfoInterval] = React.useState(null)
  const [basketBalanceInfo, setBasketBalanceInfo] = React.useState({})
  const [basketBalanceInfoInterval, setBasketBalanceInfoInterval] = React.useState(null)
  const [openMenuUploadFileCB, setOpenMenuUploadFileCB] = React.useState(false) /// Статус открытия диалогового меню в корзине календаря для загрузки файла поставщику.
  const [chosenBrandsAddToBasket, setChosenBrandsAddToBasket] = React.useState([])   // Карточка бренда. Список брендов, которые нужно добавить в корзину
  const [chosenBrandMainTable, setChosenBrandMainTable] = React.useState('')   // Карточка бренда. Выбранный бренд. Копия состояния для навбара.
  const [chosenInfoTotalMainTable, setChosenInfoTotalMainTable] = React.useState('')   // Карточка бренда. Выбрана строчка Итого
  const [workspaceApplicationParameters, setWorkspaceApplicationParameters] = React.useState([]) // Параметры рабочей области
  const [projectList, setProjectList] = React.useState([])
  const [chosenProjectList, setChosenProjectList] = useLocalStorage('chosenProjectList', {workspace: '',lastUpdate:'', central_divisions: []})

  const getAllCentralDivisions = () => {
    if('workspace_hash' in userContext.selected_workspace && userContext.selected_workspace?.is_stock_management_installed)
    simpleCallRpc(authContext,'app_smconf_get_distinct_central_division',{ workspace_hash: userContext.selected_workspace?.workspace_hash },true,userContext)
    .then(result => {
      if('data' in result)
      {
        if(Array.isArray(result.data)) {
           setAllCentralDivisions(result.data)
        }
        else {
          setAllCentralDivisions([])
        }
      }
      else {
        setAllCentralDivisions([])
      }
    })
  }

  // Кастом для РБА (разделение на проекты)
  const getAllProjects = () => {
    if('workspace_hash' in userContext.selected_workspace && userContext.selected_workspace?.is_stock_management_installed)
    simpleCallRpc(authContext,'app_smconf_get_all_project',{ workspace_hash: userContext.selected_workspace?.workspace_hash },true,userContext)
    .then(result => {
      if('data' in result)
      {
        if(Array.isArray(result.data)) {
          setProjectList(result.data)
        }
        else {
          setProjectList([])
        }
      }
      else {
        setProjectList([])
      }
    })
  }

  const handleChangeApplicationParameterValue = (parameterId, newValue) => {
    if (parameterId) {
      simpleCallRpc(authContext,'app_smconf_set_application_parameters',{
        workspace_hash: userContext.selected_workspace?.workspace_hash,
        parameter_id: parameterId,
        new_value: newValue
      },true)
      .then(result => {
        simpleCallRpc(authContext,'app_smconf_get_application_parameters',{ workspace_hash: userContext.selected_workspace?.workspace_hash },true,userContext)
        .then(result => {
          if('data' in result)
          {
            if(Array.isArray(result.data)) {
              setWorkspaceApplicationParameters(result.data)
            }
          }
        })
        .catch(error => {
          console.log('[error] app_smconf_get_application_parameters ', error)
        })
      })
    }
  }

  React.useEffect(()=>{
    if('workspace_hash' in userContext.selected_workspace && userContext.selected_workspace?.is_stock_management_installed && userContext.selectedApplication)
    {
      setLoadingAllLogisticSchema(true)
      setLoadingAllOrganizations(true)
      setLoadingAllSkuFolders(true)
      simpleCallRpc(authContext,'app_smconf_get_distinct_central_division',{ workspace_hash: userContext.selected_workspace?.workspace_hash },true,userContext)
      .then(result => {
        if('data' in result)
        {
          if(Array.isArray(result.data)) {
             setAllCentralDivisions(result.data)
          }
          else {
            setAllCentralDivisions([])
          }
        }
        else {
          setAllCentralDivisions([])
        }
      })
      if (userContext.selected_workspace?.workspace_name == 'РБА') 
      {
        getAllProjects();
      }

      simpleCallRpc(authContext,'app_smconf_get_application_parameters',{ workspace_hash: userContext.selected_workspace?.workspace_hash },true,userContext)
      .then(result => {
        if('data' in result)
        {
          if(Array.isArray(result.data)) {
            setWorkspaceApplicationParameters(result.data)
          }
        }
      })
      .catch(error => {
        console.log('[error] app_smconf_get_application_parameters ', error)
      })
    }
  // eslint-disable-next-line
  },[userContext.selected_workspace, userContext.selectedApplication])

  React.useEffect(()=>{
    if(authContext.isAuth && 'workspace_hash' in userContext.selected_workspace && userContext.selected_workspace?.is_stock_management_installed) {
      if(loadingAllLogisticSchema)
      {
        simpleCallRpc(authContext,'app_smconf_get_all_logistic_schema',{ workspace_hash: userContext.selected_workspace?.workspace_hash },true,userContext)
        .then(result => {
          if('data' in result)
            if(Array.isArray(result.data)) {
              setLogisticSchema(result.data)
            }
          setLoadingAllLogisticSchema(false)
        })
      }
    }
  // eslint-disable-next-line
  },[loadingAllLogisticSchema])

  React.useEffect(()=>{
    if ((chosenLogisticSchema.list.length === 0 || chosenLogisticSchema.workspace !== userContext.selected_workspace?.workspace_name) && logisticSchema.length > 0){
      setChosenLogisticSchema({
          workspace: userContext.selected_workspace?.workspace_name,
          list :logisticSchema.map(ls => ls.logistic_schema_id)
      })
    }
  },[logisticSchema])

  //!!! Чтобы использовать фильтрацию подразделений по логистическим схемам, нужно в app_smaa_get_all_central_division,
  // для каждого подразделения передать массив логистических схем (logistic_schema_id_list), в которое оно влючено
  React.useEffect(()=>{
    if (!loadingAllCentralDivisions && chosenLogisticSchema.list.length>0 && allDaughterDivisions.length>0)
    {
      let tmp = JSON.parse(JSON.stringify(chosenCentralDivisions))
      let useLSExclude =allDaughterDivisions.filter(item => item?.logistic_schema_id_list).length > 0 ? true : false
      if (chosenLogisticSchema.list.length === logisticSchema.length || !useLSExclude){
        tmp.exclude =[]
      }else {
        if (chosenLogisticSchema.list.length === 0){
          tmp.exclude = allDaughterDivisions.map(el => el.division_id)
        }else
        {
          tmp.exclude = allDaughterDivisions
            .filter(item => item?.logistic_schema_id_list ? !item?.logistic_schema_id_list.filter(el => chosenLogisticSchema.list.includes(el)).length > 0 : 1===1)
            .map(el => el.division_id)
        }
      }
      setChosenCentralDivisions(tmp)
      setChosenDivisionChanged(true)
    }
  },[allDaughterDivisions,chosenLogisticSchema])


  React.useEffect(()=>{
    if (!loadingAllCentralDivisions && chosenProjectList.central_divisions.length>0 && allDaughterDivisions.length>0)
    {
      let tmp = JSON.parse(JSON.stringify(chosenCentralDivisions))
      let useLSExclude =allDaughterDivisions.filter(item => item?.central_division_id_list).length > 0 ? true : false
      if (chosenProjectList.central_divisions.length === projectList.length || !useLSExclude){
        tmp.exclude =[]
      }else {
        if (chosenProjectList.central_divisions.length === 0){
          tmp.exclude = allDaughterDivisions.map(el => el.division_id)
        }else
        {
          tmp.exclude = allDaughterDivisions
            .filter(item => item?.central_division_id_list ? !item?.central_division_id_list.filter(el => chosenProjectList.central_divisions.includes(el)).length > 0 : 1===1)
            .map(el => el.division_id)
        }
      }
      setChosenCentralDivisions(tmp)
      setChosenDivisionChanged(true)
    }
  },[allDaughterDivisions,chosenProjectList])

  const handleUpdateChosenList = (chosen_list, fullList, listName, idName, setList, allChecked, chosenName,id='') => {
    let temp = JSON.parse(JSON.stringify(chosen_list))
    if (chosenName){
      let chosenId = Number(id)
      if (chosenId !== null){
        let currentIndex = temp[`${listName}`].indexOf(chosenId)
        if(currentIndex !== -1){
          temp[`${listName}`].splice(currentIndex, 1)
        }else{
          temp[`${listName}`].push(chosenId)
        }
      }
    }
    else {
      if (allChecked){
        temp[`${listName}`]=fullList.map(el => el[`${idName}`])
      }else{
        temp[`${listName}`]=[]
      }
    }
    setList(temp)
    setChosenDivisionChanged(true)
  }

  const handleUpdateChosenLogisticSchemaList = (allChecked,name = '',id='') =>{
    handleUpdateChosenList(chosenLogisticSchema,logisticSchema,'list','logistic_schema_id',setChosenLogisticSchema,allChecked,name,id)
  }

  const handleUpdateChosenCentralDivisionsList = (allChecked,name = '',id='') =>{
    handleUpdateChosenList(chosenCentralDivisions,centralDivisionsOfChosenLogisticSchema,'locations','division_id',setChosenCentralDivisions,allChecked,name,id)
  }

  const handleUpdateChosenProjectList = (allChecked,name = '',id='') =>{
    handleUpdateChosenList(chosenProjectList,projectList,'central_divisions','division_id',setChosenProjectList,allChecked,name,id)
  }

  React.useEffect(()=>{
    if (logisticSchema.length > 0){
      setLoadingAllCentralDivisions(true)
    }
  },[logisticSchema])

  React.useEffect(()=>{
    setChosenDivisionsListChanges(true)
  },[chosenCentralDivisions])

  React.useEffect(()=>{
    if(authContext.isAuth && 'workspace_hash' in userContext.selected_workspace && userContext.selected_workspace?.is_stock_management_installed && loadingAllCentralDivisions)
    {
      simpleCallRpc(authContext,'app_smaa_get_all_central_division',{
        workspace_hash: userContext.selected_workspace?.workspace_hash,
        logistic_schema: logisticSchema.map(el => el.logistic_schema_id),
      },true,userContext)
      .then(result => {
        if ("data" in result) {
          setLoadingAllCentralDivisions(false)
          setAllDaughterDivisions(result.data)
          if (userContext.selectedApplication?.application_sys_name !== 'basket') {
            setCentralDivisionsOfChosenLogisticSchema(result.data)
            if (!chosenCentralDivisions.workspace || chosenCentralDivisions.workspace !== userContext.selected_workspace?.workspace_name || !chosenCentralDivisions.locations.length)
            {
              let tmp = JSON.parse(JSON.stringify(chosenCentralDivisions))
              if (tmp.workspace !== userContext.selected_workspace?.workspace_name){
                tmp.exclude =[]
                tmp.workspace = userContext.selected_workspace?.workspace_name
              }
              setChosenCentralDivisions(tmp)
            }
          } 
          else if (allCentralDivisions && userContext.selectedApplication?.application_sys_name === 'basket') {
            setCentralDivisionsOfChosenLogisticSchema(allCentralDivisions)
            if (!chosenCentralDivisions.workspace || chosenCentralDivisions.workspace !== userContext.selected_workspace?.workspace_name || !chosenCentralDivisions.locations.length)
              setChosenCentralDivisions({
                workspace: userContext.selected_workspace?.workspace_name,
                locations: allCentralDivisions.map(d => d.division_id)
              })
          }
          setChosenDivisionChanged(true)
        }
      })
    }
  // eslint-disable-next-line
  },[loadingAllCentralDivisions])

  const getBasketInfo = () => {
    if(userContext.selected_workspace?.is_stock_management_installed && userContext.selectedApplication?.application_sys_name === 'basket')
    simpleCallRpc(authContext,'app_smb_get_basket_info',{
      workspace_hash: userContext.selected_workspace?.workspace_hash,
      do_not_delete_user_id_from_arg: true
    },true,userContext)
    .then(result => {
      if('quantity' in result?.data)
        setBasketInfo(result.data)
    })
  }

  const getBasketBalanceInfo = () => {
    if (userContext.selected_workspace?.is_stock_management_installed && userContext.selectedApplication?.application_sys_name === 'balance-basket')
    simpleCallRpc(authContext,'app_smbb_get_basket_balance_info',{
      workspace_hash: userContext.selected_workspace?.workspace_hash,
      do_not_delete_user_id_from_arg: true
    },true,userContext)
    .then(result => {
      if('quantity' in result?.data)
        setBasketBalanceInfo(result.data)
    })
  }

  useEffect(() => {
    if(userContext.selected_workspace?.is_stock_management_installed && userContext.selectedApplication?.application_sys_name === 'basket')
    {
      getBasketInfo()
      setBasketInfoInterval(setInterval(() => {
        getBasketInfo()
      }, 310000))
    }
    else if (userContext.selected_workspace?.is_stock_management_installed && userContext.selectedApplication?.application_sys_name === 'balance-basket')
    {
      getBasketBalanceInfo()
      setBasketBalanceInfoInterval(setInterval(() => {
        getBasketBalanceInfo()
      }, 310000))
    }
    else 
    {
      if(basketInfoInterval!==null)
        clearInterval(basketInfoInterval)
    }
  // eslint-disable-next-line
  },[userContext.selected_workspace, userContext.selectedApplication])

  React.useEffect(() => {
    if (authContext.isAuth && 'workspace_hash' in userContext.selected_workspace && userContext.selected_workspace?.is_stock_management_installed) {
      if (loadingAllOrganizations && userContext.selected_workspace?.workspace_name == 'Chinaspare') { //&& userContext.selected_workspace?.workspace_name == 'Chinaspare'
        simpleCallRpc(authContext, 'app_smb_get_all_organizations', {
          workspace_hash: userContext.selected_workspace?.workspace_hash
        }, true, userContext)
          .then(result => {
            if ("data" in result) {
              setLoadingAllOrganizations(false)
              setOrganizations(result.data)
              if (userContext.selectedOrganization == ''){
                userContext.setSelectedOrganization(result.data[0].ref_key)
              }
            }
          })
      }
    }
    // eslint-disable-next-line
  }, [loadingAllOrganizations])

  React.useEffect(() => {
    if (authContext.isAuth && 'workspace_hash' in userContext.selected_workspace && userContext.selected_workspace?.is_stock_management_installed) {
      if (loadingAllSkuFolders && workspaceApplicationParameters.find(el => el.parameter === 'USE_LOCATION_BRAND_BUNDLE')?.value === 'true') {
        simpleCallRpc(authContext, 'app_smaa_get_all_sku_folders', {
          workspace_hash: userContext.selected_workspace?.workspace_hash
        }, true, userContext)
          .then(result => {
            if ("data" in result) {
              setLoadingAllSkuFolders(false)
              setSkuFolders(result.data)

              if (!chosenSkuFolders.workspace || chosenSkuFolders.workspace !== userContext.selected_workspace?.workspace_name || !chosenSkuFolders.folders.length)
              setChosenSkuFolders({
                workspace: userContext.selected_workspace?.workspace_name,
                folders: result.data?.map(d => d.sku_id)
              })
              setChosenSkuFoldersChanged(true)
            }
          })
      }
    }
    // eslint-disable-next-line
  }, [loadingAllSkuFolders, workspaceApplicationParameters])
  
  return (
    <SMContext.Provider
      value={{
        allCentralDivisions,
        setAllCentralDivisions,
        allDaughterDivisions,
        setAllDaughterDivisions,
        centralDivisionsOfChosenLogisticSchema,
        setCentralDivisionsOfChosenLogisticSchema,
        chosenCentralDivisions,
        setChosenCentralDivisions,
        logisticSchema,
        setLogisticSchema,
        chosenLogisticSchema,
        updateBasketCounter,
        setUpdateBasketCounter,
        clearBasketCounter,
        setClearBasketCounter,
        basketInfo,
        getBasketInfo,
        basketBalanceInfo,
        setBasketBalanceInfo,
        basketBalanceInfoInterval,
        setBasketBalanceInfoInterval,
        openMenuUploadFileCB,
        setOpenMenuUploadFileCB,
        getBasketBalanceInfo,
        loadingAllCentralDivisions,
        setLoadingAllCentralDivisions,
        loadingAllLogisticSchema,
        chosenDivisionChanged,
        setChosenDivisionChanged,
        chosenBrandsAddToBasket,
        setChosenBrandsAddToBasket,
        chosenBrandMainTable,
        setChosenBrandMainTable,
        chosenInfoTotalMainTable,
        setChosenInfoTotalMainTable,
        getAllCentralDivisions,
        workspaceApplicationParameters, 
        handleUpdateChosenLogisticSchemaList,
        handleUpdateChosenCentralDivisionsList,
        chosenDivisionListChanged,
        organizations,
        setOrganizations,
        skuFolders,
        setSkuFolders,
        chosenSkuFolders,
        setChosenSkuFolders,
        chosenSkuFoldersChanged,
        setChosenSkuFoldersChanged,
        handleChangeApplicationParameterValue,
        projectList,
        getAllProjects,
        chosenProjectList,
        handleUpdateChosenProjectList
      }}
      {...props}
    />
  );
}

export { SMProvider, SMContext }
