import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import logo from './retair_logo.png';
import Footer from './components/login/Footer';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: theme.spacing(30),
    height: theme.spacing(15),
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
  },
}));

const sendRecoveryData = async (userData, setError, setLinkSended) => {
  const response = await fetch('/send_recovery_link', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(userData),
  });
  const result = await response.json();

  if (!result.is_ok) {
    setError(result.message);
    return;
  }
  setLinkSended(true);
};
const ForgotPasswordForm = ({ setError, setLinkSended }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      setError(null);
      console.log(values);
      sendRecoveryData(values, setError, setLinkSended);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <TextField
        name="email"
        type="email"
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label={t('common.email')}
        autoComplete="email"
        autoFocus
        onChange={formik.handleChange}
        value={formik.values.email}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t('login.resetThePassword')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const Register = () => {
  const navigate = useNavigate()
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [linkSended, setLinkSended] = useState(false);
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <img onClick={() => navigate('/')} src={logo} className={classes.logo} alt="Логотип Retair" />

        <Box pb={5} style={{ width: '100%' }}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => navigate('/')}
            startIcon={<ArrowBackIcon />}
          >
            {t('registration.back_to_authorization')}
          </Button>
        </Box>

        <Typography component="h1" variant="h5">
          {t('login.passwordRecovery')}
        </Typography>
        {error ? <Alert severity="error">{error}</Alert> : null}
        {!linkSended ? <ForgotPasswordForm setError={setError} setLinkSended={setLinkSended} /> : (
          <div>
            <Box py={3}>
              <Alert severity="success">
                <Typography variant="body2">
                  {t('login.linkSent')}
                </Typography>
                <br />
                <Typography variant="body2">{t('login.linkTimeout')}</Typography>
              </Alert>
            </Box>
          </div>
        )}
        <Footer />
      </div>
    </Container>
  );
};

export default Register;
