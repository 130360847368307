import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

export default function Copyright() {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`${t('common.copyright')} © `}
      <Link color="inherit" href="https://retair.ru/">
        retair.ru
      </Link>
      {' '}
      {'2020 - '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
