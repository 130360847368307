import React, { useContext } from 'react'

import { ThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as ThemeProviderMui } from '@mui/material/styles'

import { AuthContext } from './context/auth-context';
import { UserContext } from './context/user-context';
import { ErrorBoundary } from 'react-error-boundary'
import { SettingsContext } from './context/settings-context';
import UserActivity from './utils/user-activity/index';
import Loader from './Loader';

const AuthenticatedAppComponent = import('./authenticated-app');
const AuthenticatedApp = React.lazy(() => AuthenticatedAppComponent);
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'));


const myErrorHandler = (error, componentStack, userContext) => {
  console.log('error=', error)
  console.log('componentStack=', componentStack)
  console.log('userContext=', userContext)
  fetch('/api/data/rpc/send_error_from_frontend/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({ error: error.message, componentStack: componentStack, pathSite: window.location.href, username: userContext?.userInfo?.name + ' ' + userContext?.userInfo?.family_name, workspace_name: userContext?.selected_workspace?.workspace_name }),
    json: true,
  })
  .then(res => {
    //console.log('why?', res)
  })
}


function App() {
  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)
  const { theme, themeMui } = useContext(SettingsContext)

  return (
    <ErrorBoundary onError={(e,c) => myErrorHandler(e,c,userContext)} fallback={<Loader />}>
      <React.Suspense fallback={<Loader />}>
        <ThemeProviderMui theme={themeMui}>
          <ThemeProvider theme={theme}>
            <UserActivity />
            {authContext.isAuth ? <AuthenticatedApp /> : <UnauthenticatedApp />}
          </ThemeProvider>
        </ThemeProviderMui>
      </React.Suspense>
    </ErrorBoundary>
  );
}

export default App;
