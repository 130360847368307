import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  loaderWrapper: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
  },
  loaderWrapperCenter: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Loader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.loaderWrapper}>
      <Box className={classes.loaderWrapperCenter}>
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default Loader;
