export const sendCustomErrorToTelegram = (error, componentStack) => {
    fetch('/api/data/rpc/send_error_from_frontend/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({ error: error, componentStack: componentStack, pathSite: window.location.href }),
        json: true,
      })
      .then(res => {
        //console.log('why?', res)
      })
}
